import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" gtmPageType="404" gtmPageName="404" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    {/* <!-- DO NOT REMOVE --> */}
    <span className="hidden commit--display">fake</span>
  </Layout>
)

export default NotFoundPage
